.tab-button-menu {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tab-button-menu-text {
    text-decoration: none;
    color: black;
    margin-left: 10px;
    font-size: 19px;
}

.menu-wrapper {
    height: calc(100vh - 64px - 32px);
    width: 256px;
    background-color: white;
    padding-top: 32px;
}

.category-item-menu-wrapper {
    margin-top: 12px;
    text-align: left;
    cursor: pointer;
    font-size: 21px;
}

.workflow-menu-header-title {
    font-size: 25px;
    margin-left: 20px;
    display: flex;
}

.category-item-menu-text {
    text-decoration: none;
}

.category-menu-expandable-trigger {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.category-menu-expandable-arrow {
    width: 20px!important;
    height: 20px!important;
    align-self: center;
}

.category-menu-collapsible-wrapper {
    display: flex;
    flex-direction: column;
}

.user-menu-wrapper {
    margin-top: 32px;
}

.inner-margin-user-menu {
    margin-left: 32px;
    margin-right: 22px;
}

.CIM-menu-button {
    display: flex;
    margin-left: 26px;
    margin-right: 22px;
    border: none;
    background-color: inherit;
    cursor: pointer;
    height: 32px;
}

.CIM-menu-button-text {
    font-size: 21px;
    font-weight: 250;
    color: #374c72;
    translate: 19% -75%;
}

.horizontal-line-menu {
    border: 1px solid  var(--lightGrayishBlue);
    margin-top: 30px;
    margin-bottom: 30px;
}

.disabled-category-menu-trigger {
    cursor: pointer;
}

.workflow-menu-header-wrapper {
    margin-left: 24px;
    margin-bottom: 16px;
}

.workflow-menu-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.workflow-menu-back-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
